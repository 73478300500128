import React from "react"
import { GLOBAL_TILL_ALT } from "../../../constants/Constants"

const image = require("../../../images/globaltill/background.png")

const BackgroundImage = props => {
  return <img src={image} alt={GLOBAL_TILL_ALT} {...props} />
}

export default BackgroundImage
