import React from "react"
import ReactCompareImage from "react-compare-image"
import IntlText from "../../components/common/intl-text-2"
import Text from "../../components/common/text"
import WebIcon from "../../components/icons/WebIcon"
import Layout from "../../components/layout"
import BackgroundImage from "../../components/projects/globaltill-images/background"
import Content1Image from "../../components/projects/globaltill-images/content-1"
import Content2Image from "../../components/projects/globaltill-images/content-2"
import Content3Image from "../../components/projects/globaltill-images/content-3"
import Content4Image from "../../components/projects/globaltill-images/content-4"
import Content5Image from "../../components/projects/globaltill-images/content-5"
import LogoImage from "../../components/projects/globaltill-images/logo"
import Screenshot1Image from "../../components/projects/globaltill-images/screenshot-1"
import Screenshot2Image from "../../components/projects/globaltill-images/screenshot-2"
import Screenshot3Image from "../../components/projects/globaltill-images/screenshot-3"
import Screenshot4Image from "../../components/projects/globaltill-images/screenshot-4"
import ProjectContainer from "../../components/projects/project-container"
import ProjectContent from "../../components/projects/project-content"
import ProjectIntro from "../../components/projects/project-intro"
import { GLOBAL_TILL_ALT } from "../../constants/Constants"

const before1 = require("src/images/globaltill/compare-before-1.png")
const after1 = require("src/images/globaltill/compare-after-1.png")
const before2 = require("src/images/globaltill/compare-before-2.png")
const after2 = require("src/images/globaltill/compare-after-2.png")
const before3 = require("src/images/globaltill/compare-before-3.png")
const after3 = require("src/images/globaltill/compare-after-3.png")
const before4 = require("src/images/globaltill/compare-before-4.png")
const after4 = require("src/images/globaltill/compare-after-4.png")
const before5 = require("src/images/globaltill/compare-before-5.png")
const after5 = require("src/images/globaltill/compare-after-5.png")

const tags = ["UX", "UI", "Frontend"]

const stores = [
  {
    store: "web",
    icon: <WebIcon />,
    link: "https://globaltill.com/",
  },
]

const technologies = "React, Redux, Electron"

const HowWeHelp = () => {
  return (
    <div className="row how-we-help">
      <div className="col-12 col-lg-6 left-column">
        <IntlText id="globaltill.body2" component="h1" />
      </div>
      <div className="col-12 col-lg-6 right-column">
        <IntlText id="globaltill.body3" component="h2" />
      </div>
    </div>
  )
}

const WhatWeDo = () => {
  return (
    <div className="row what-we-do">
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="technologies" />
        <Text>
          <strong>{technologies}</strong>
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="platforms" />
        <Text>
          <IntlText id="globaltill.platforms" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="services" />
        <Text>
          <IntlText id="globaltill.services" component="strong" />
        </Text>
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <Text id="projectContent.explore" />
        <div className="store-icons">
          {stores.map((value, key) => (
            <a
              key={key}
              href={value.link}
              target="_blank"
              rel="noopener noreferrer"
              title={value.store}
            >
              {value.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

const Screenshots = () => {
  return (
    <div className="screenshots-container globaltill">
      <div className="images">
        <Screenshot1Image className="screenshot-img" />
        <Screenshot2Image className="screenshot-img" />
        <Screenshot3Image className="screenshot-img" />
        <Screenshot4Image className="screenshot-img" />
      </div>
    </div>
  )
}

const Compare = ({ before, after }) => {
  return (
    <div className="compare-image d-none d-md-block">
      <div className="compare-titles">
        <IntlText id="before" component="span" />
        <IntlText id="after" component="span" />
      </div>
      <ReactCompareImage leftImage={before} rightImage={after} leftImageAlt={GLOBAL_TILL_ALT} rightImageAlt={GLOBAL_TILL_ALT} />
    </div>
  )
}

const GlobalTillPage = () => {
  return (
    <Layout
      pageTitle="Projects"
      title="GlobalTill´s  software development challenge - Sysgarage"
      description="Check out the software development project for GlobalTill that our company has successfully tackled. ​Let us show you how we can help!"
      hideHeader
      noMargin
      hideContact
    >
      <ProjectContainer>
        <div className="sg-container">
          <ProjectIntro
            tags={tags}
            logo={<LogoImage className="logo-image" />}
            textId="globaltill.body1"
            background={<BackgroundImage className="background-image" />}
          />
          <HowWeHelp />
          <WhatWeDo />
        </div>
        <ProjectContent
          titleId="globaltill.body4"
          descriptionId="globaltill.body5"
          imageMobile={<Content1Image />}
        />
        <Compare before={before1} after={after1} />
        <ProjectContent
          titleId="globaltill.body6"
          descriptionId="globaltill.body7"
          imageMobile={<Content2Image />}
        />
        <Compare before={before2} after={after2} />
        <ProjectContent
          titleId="globaltill.body8"
          descriptionId="globaltill.body9"
          imageMobile={<Content3Image />}
        />
        <Compare before={before3} after={after3} />
        <ProjectContent
          titleId="globaltill.body10"
          descriptionId="globaltill.body11"
          imageMobile={<Content4Image />}
        />
        <Compare before={before4} after={after4} />
        <ProjectContent
          titleId="globaltill.body12"
          descriptionId="globaltill.body13"
          imageMobile={<Content5Image />}
        />
        <Compare before={before5} after={after5} />
        <ProjectContent
          titleId="globaltill.body14"
          descriptionId="globaltill.body15"
        >
          <Screenshots />
          <div className="blank-space" />
        </ProjectContent>
      </ProjectContainer>
    </Layout>
  )
}

export default GlobalTillPage
