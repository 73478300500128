import React from "react"
import { GLOBAL_TILL_ALT } from "../../../constants/Constants"

const image = require("../../../images/globaltill/screenshot-3.png")

const Screenshot3Image = props => {
  return <img src={image} alt={GLOBAL_TILL_ALT} {...props} />
}

export default Screenshot3Image
