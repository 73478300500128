import React from "react"
import { GLOBAL_TILL_ALT } from "../../../constants/Constants"

const image = require("../../../images/globaltill/content-4.png")

const Content4Image = props => {
  return <img src={image} alt={GLOBAL_TILL_ALT} {...props} />
}

export default Content4Image
