import React from "react"
import { GLOBAL_TILL_ALT } from "../../../constants/Constants"

const image = require("../../../images/globaltill/screenshot-2.png")

const Screenshot2Image = props => {
  return <img src={image} alt={GLOBAL_TILL_ALT} {...props} />
}

export default Screenshot2Image
